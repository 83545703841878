import Vue from 'vue';
import Axios from 'axios';

import { App } from '@app/core';
import { router } from '@shared/router';
import { store } from '@shared/store';
import VueAutosize from 'vue-autosize';
import * as ModalDialogs from 'vue-modal-dialogs'
import Snotify, { SnotifyPosition } from 'vue-snotify';

import 'intersection-observer';

Vue.use(ModalDialogs);
Vue.use(VueAutosize);
Vue.use(Snotify, {
    toast: {
        position: SnotifyPosition.rightBottom
    }
});

Vue.config.productionTip = false;

// Axios Interceptors

// Add Token
Axios.interceptors.request.use((config) => {
    const token = store.getters.authenticationToken;

    if (token)
        config.headers.Authorization = `Bearer ${token}`;

    return config;
}, (error) => Promise.reject(error));

// Check for 401 Errors
Axios.interceptors.response.use(response => response, (error) => {
    if (error.response.status === 401 && !error.config._retry) {
        error.config._retry = true;

        store.commit('resetAuthentication');
        router.push('/authentication/signin');
    }

    return Promise.reject(error);
});

new Vue({
    store,
    router,
    render: (h) => h(App)
}).$mount('#app');
