import { RouteConfig } from 'vue-router';
import { LazyLoadRoute } from '@shared/router/util';
import { store } from '@shared/store';

export const AddPollRoutes: RouteConfig[] = [
    {
        name: 'AddPoll',
        path: '/addpoll',
        component: () => LazyLoadRoute(import('./AddPoll.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'] && store.getters['isAdministrator'])
                return next();

            return next('/authentication/signin');
        }
    },
];
