const APIBase = `https://fhapi.bizzy.live/api/`; 

export default {
    LoginURL: `${APIBase}auth/signin`,         // URL for Login Request
    RegisterURL: `${APIBase}auth/signup`,      // URL for Register Request
    ResetPassURL: `${APIBase}auth/resetpwd`,
    NotifURL: `${APIBase}auth/notifications`,  // URL to retrieve notifications
    EnableTFAUrl: `${APIBase}auth/enable-tfa`,
    DisableTFAUrl: `${APIBase}auth/disable-tfa`,
    AchNewURL: `${APIBase}users/achievements/new`,
    AchURL: `${APIBase}users/achievements`,

    StreamURL: `${APIBase}images`,
    PollsURL: `${APIBase}polls`,
    QuotesURL: `${APIBase}quotes`,
    RateURL: `${APIBase}images/rate`,
    ShareURL: `${APIBase}images/share`,

    NotificationsURL: `${APIBase}auth/notifications`,

    UpdateAvatarURL: `${APIBase}users/avatar/upload`,
    UpdateBioURL: `${APIBase}users/bio`,

    UploadURL: `${APIBase}images/upload`,

    // Polls
    CreatePollURL: `${APIBase}polls/create`,

    // Admin URLS
    AdminPendingImagesURL: `${APIBase}admin/new-images`,
    AdminPendingUsersURL: `${APIBase}admin/new-users`,

    AdminApproveUserURL: `${APIBase}admin/approve-user`,
    AdminDeleteUserURL: `${APIBase}admin/delete-user`,

    AdminApproveImageURL: `${APIBase}admin/approve-image`,
    AdminDeleteImageURL: `${APIBase}admin/delete-image`,

    AdminFeatureImageURL: `${APIBase}admin/feature`,
    AdminUnFeatureImageURL: `${APIBase}admin/unfeature`,

    AdminAddAdminURL: `${APIBase}admin/add-admin`,
    AdminRemoveAdminURL: `${APIBase}admin/remove-admin`,

    AdminAddVIPURL: `${APIBase}admin/add-vip`,
    AdminRemoveVIPURL: `${APIBase}admin/remove-vip`,

    AdminBanUserURL: `${APIBase}admin/ban-user`,
    AdminUnbanUserURL: `${APIBase}admin/unban-user`,

    AdminUserListURL: `${APIBase}admin/users`,
    
    AdminUserByNameURL: `${APIBase}admin/user-byname`
    
}