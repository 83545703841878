







import { Vue, Component, Watch } from 'vue-property-decorator';
import { State, Mutation, Getter } from 'vuex-class';
import { IconButton } from '@components/UI';
import { Theme } from '@shared/store/modules/SettingsModule';

@Component({
    components: { IconButton }
})
export default class ThemeSwitcher extends Vue {
    
    private icon = 'moon';

    @Getter
    private currentTheme: string;

    @Mutation('updateTheme')
    private updateTheme: Function;

    @Watch('currentTheme', { immediate: true })
    public updateGlobalTheme() {
        (document.querySelector('html') as HTMLElement).setAttribute('theme', this.currentTheme);
    }

    private toggleTheme() {
        this.updateTheme(this.currentTheme === Theme.Dark ? Theme.Light : Theme.Dark);
    }

}

