







import { Vue, Component, Prop } from 'vue-property-decorator';
import { Icon } from '@components/UI';

@Component({
    components: { Icon }
})
export default class IconButton extends Vue {
    
    @Prop()
    public icon: string;

    @Prop()
    public isDisabled: boolean;

    private onClick(e) {
        this.$emit('click', e);
    }

}

