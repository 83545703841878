






































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Textfield extends Vue {

    @Prop({ default: '' })
    public value: string | number;

    @Prop({ type: String, default: '' })
    public label: string;

    @Prop({ type: String, default: '' })
    public icon: string;

    @Prop({ type: String, default: '' })
    public type: string;

    @Prop({ type: String, default: '' })
    public error: string;

    @Prop({ type: Boolean, default: false })
    public required: boolean;

    @Prop({ type: Boolean, default: false })
    public disabled: boolean;

    @Prop({ type: Boolean, default: false })
    public isTextarea: boolean;

    @Prop()
    public isValid: boolean;

    public focused = false;

    @Prop({ type: String, default: '' })
    public hint: string;

    private onInput(e) {
        this.$emit('input', e.target.value);
    }

    private onFocus() { this.focused = true; }

    private onBlur() { this.focused = false; }

    private hasValue() {
        if(this.type == "number")
            return (this.value != null);
        else
            return ((this.value as string).length > 0);
    }
    
}

