// Generated by https://quicktype.io

export interface IStream {
    success:   boolean;
    images:    IPost[];
    page:      number;
    pageCount: number;
    featured:  IPost;
}

export interface IPost {
    id:          number;
    title:       string;
    userId:      number;
    approved:    boolean;
    date:        number;
    contentType: IContentType;
    fileName:    string;
    isHeader:    boolean;
    ratings:     IRating[];
    user?:       IUser;

    isVideo:     boolean;
    videoLength: number;
}

export enum IContentType {
    ImageJPEG = 'image/jpeg',
    ImagePNG = 'image/png',
}

export interface IRating {
    ratingId:   number;
    userId:     number;
    ratingType: number;
}

export interface IUser {
    id:         number;
    isAdmin:    boolean;
    isVIP:      boolean;
    isBanned:   boolean;
    username:   string;
    discordId:  string;
    profile:    IProfile | null;
    tfaEnabled: boolean;
    banReason:  string;
}

export interface IProfile {
    bio:               string;
    avatar:            string;
    avatarContentType: IContentType;
}

export interface IRatings {
    upvotes: number;
    downvotes: number;
}

export enum VoteType {
    UPVOTE = 1,
    DOWNVOTE = 2,
    REMOVE = 3,
}

export interface IPoll {
    id:                string,
    title:             string,
    allowMultiple:     boolean,
    allowUserOptions:  boolean,

    votes:             IPollVote[],
    options:           IPollOption[],

    author:            IUser,

    pollStart:         Date,
    pollEnd:           Date
}

export interface IPollOption {
    id:  number,
    text: string,
    color: string,
    created: number,
    author: IUser
}
export interface IPollVote {
    id:      number,
    voterId: number,
    options: number[],
    created: number
}