import Axios from 'axios';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';

@Module
export class TwitchModule extends VuexModule {
    private clips: any = [];

    public get clipStream() {
        return this.clips;
    }

    @Mutation 
    clipLoad({clips}) {
        console.log("setter", clips)
        this.clips = clips;
    }

    @Action
    public async getFrostyStream() {
        try {
            /*var resp =  await Axios.get("https://api.twitch.tv/helix/streams?user_login=stay_frosty641", {
                headers: {
                    "Authorization": null,
                    "Client-Id": "pjt5jh3nakw2509rs6geg475g4zwki"
                }
            });

            return resp.data;*/
            return null;
        } catch (error) { 
            return Promise.reject(error);
        }
    }

    @Action
    public async loadClipStream() {
        try {
            /*var resp = await Axios.get("https://api.twitch.tv/helix/clips?broadcaster_id=58132675&first=8", {
                headers: {
                    "Authorization": null,
                    "Client-Id": "pjt5jh3nakw2509rs6geg475g4zwki"
                }
            });*/

            //console.log("clips", resp.data.data);
            this.context.commit('clipLoad', {clips: []});
            return [];
        }catch (error) {
            return Promise.reject(error);
        }
    }
}