import { RouteConfig } from 'vue-router';
import { LazyLoadRoute } from '@shared/router/util';
import { store } from '@shared/store';

export const PollRoutes: RouteConfig[] = [
    {
        name: 'Polls',
        path: '/polls',
        component: () => LazyLoadRoute(import('./PollStream.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'])
                return next();

            return next('/authentication/signin');
        }
    },
    {
        name: 'ViewPoll',
        path: '/polls/:id',
        component: () => LazyLoadRoute(import('./ViewPoll.vue'))

        /*beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'])
                return next();

            return next('/authentication/signin');
        }*/
    }
];
