import Axios from 'axios';
import API from '@shared/config/api';

import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { IUser } from '@shared/types';

@Module
export class AuthenticationModule extends VuexModule {

    public authenticated: boolean = false;
    public token: string | null = null;
    public user: IUser | null = null;

    public get isAuthenticated(): boolean {
        return this.authenticated;
    }

    public get isAdministrator(): boolean {
        if (this.user)
            return this.user.isAdmin;
            
        return false;
    }

    public get isVip(): boolean {
        if(this.user)
            return this.user.isVIP;
        
        return false;
    }

    public get currentUser(): any {
        return this.user;
    }
    
    public get authenticationToken(): string | null {
        return this.token;
    }

    @Mutation
    public resetAuthentication() {
        this.authenticated = false;
        this.token = null;
        this.user = null;
    }

    @Mutation 
    enableTfaMem() {
        if(this.user != null)
            this.user.tfaEnabled = true;
    }

    @Mutation
    public disableTfaMem() {
        if(this.user != null)
            this.user.tfaEnabled = false;
    }

    @Mutation
    private commitLogin(data): void {
        if(data.success && data.user){
            var user = data.user;
            
            this.authenticated = true;
            this.token = user.token;
            this.user = user;
        }
    }

    @Mutation
    public setAvatar(avatar) {
        if (this.user && this.user.profile)
            this.user.profile.avatar = avatar.avatar;
    }

    @Mutation
    public setBio(bio) {
        if (this.user && this.user.profile)
            this.user.profile.bio = bio.bio;
    }

    @Action({ commit: 'commitLogin', rawError: true })
    public async login({ username, password, pin }): Promise<any> {
        try {
            const response = await Axios.post(API.LoginURL, { username, password, pin });

            if (!response.data.success)
                return Promise.reject(response.data);
            
            return response.data;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);

            return Promise.reject();
        }
    }

    @Action
    public async checkNewAchievements() {
        if(!this.authenticated)
            return {success: false};
        try {
            const resp = await Axios.get(API.AchNewURL);
            return resp.data;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);

            return Promise.reject();
        }
    }

    @Action({rawError: true})
    public async register({ username, password, discordId, gresp }) {
        try {
            const response = await Axios.post(API.RegisterURL, { username, password, discordId, recaptcha: gresp });

            if (!response.data.success)
                return Promise.reject(response.data);

            return response.data;
            //throw 'Signup disabled';
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);

            return Promise.reject();
        }
    }

    @Action({rawError: true})
    public async resetPass({code, password}){
        try {
            const response = await Axios.post(API.ResetPassURL, { code, password });

            if (!response.data.success)
                return Promise.reject(response.data);

            return response.data;
        }
        catch(error) {
            if (error.response.data)
                return Promise.reject(error.response.data);

            return Promise.reject();
        }
    }

    @Action
    public async enableTfa({code}) {
        try {
            if(code == null)
                code = -1;

            const response = await Axios.post(API.EnableTFAUrl, { pin: code });
            if(response.data.success && !response.data.qrCode)
                this.context.commit('enableTfaMem');

            return response.data;
        } catch (error) {
            console.log(error);
            if (error.response.data)
                return Promise.reject(error.response.data);

            return Promise.reject();
        }
    }

    @Action
    public async disableTfa({code}) {
        try {
            if(code == null)
                code = -1;

            const response = await Axios.post(API.DisableTFAUrl, { pin: code });
            if(response.data.success)
                this.context.commit('disableTfaMem');

            return response.data;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);

            return Promise.reject();
        }
    }

    @Action({ commit: 'setAvatar', rawError: true })
    public async updateAvatar({ image }) {
        try {
            const form = new FormData();
            form.append('image', image);

            const response = await Axios.post(API.UpdateAvatarURL, form, { headers: { 'Content-Type': 'multipart/form-data' } });

            if (!response.data.success)
                return Promise.reject(response.data);

            return response.data;
        } catch (error) {
            if (error.response.data)
                    return Promise.reject(error.response.data);
                
                return Promise.reject();
        }
    }

    @Action({ commit: 'setBio', rawError: true })
    public async updateBio({ bio }) {
        try {
            const response = await Axios.post(API.UpdateBioURL, { bio });

            if (!response.data.success)
                return Promise.reject(response.data);

            return response.data;
        } catch (error) {
            if (error.response.data)
                    return Promise.reject(error.response.data);
                
                return Promise.reject();
        }
    }

    @Action
    public async logout() {
        this.context.commit('resetStream');
        this.context.commit('resetNotifications');
        this.context.commit('resetAdministrator');
        this.context.commit('resetAuthentication');

        return;
    }

}