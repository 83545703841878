import { RouteConfig } from 'vue-router';
import { LazyLoadRoute } from '@shared/router/util';
import { store } from '@shared/store';

export const ClipStreamRoutes: RouteConfig[] = [
    {
        name: 'ClipStream',
        path: '/clips',
        component: () => LazyLoadRoute(import('./ClipStream.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'])
                return next();

            return next('/authentication/signin');
        }
    },
];
