











import { Vue, Component } from 'vue-property-decorator';
import { NavigationLink } from './index';

@Component({
    components: { NavigationLink }
})
export default class MobileNavigation extends Vue {
    
}

