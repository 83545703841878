import { RouteConfig } from 'vue-router';
import { LazyLoadRoute } from '@shared/router/util';

export const AboutRoutes: RouteConfig[] = [
    {
        name: 'About',
        path: '/about',
        component: () => LazyLoadRoute(import('./About.vue')),
    },
];
