














import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { DropdownMenu, Icon, IconButton, Item } from '@components/UI';

@Component({
    components: { DropdownMenu, Icon, IconButton, Item }
})
export default class Notifications extends Vue {
    
    @Getter
    public unreadNotificationsCount: number;

    @Getter
    public notificationsStream: number;

    @Getter
    public isAuthenticated: boolean;

    @Action
    public loadNotifications: () => Promise<any>;

    public mounted() {
        this.loadNotifications();
    }

}

