
















import { Vue, Component } from 'vue-property-decorator';

/**
 * Dropdown Menu Component[for navbar]
 */
@Component
export default class DropdownMenu extends Vue {
    
    /**
     * If dropdown is visible
     */
    private shown: boolean = false;
    private listener: any = null;

    /**
     * Open dropdown menu
     * Emits 'open' event
     */
    private open(): void {
        this.shown = true;
        this.$emit('open');
        window.addEventListener('keydown', this.close);
    }

    /**
     * Close drop down menu
     * Emits 'close' event
     */
    private close(e): void {
        if (this.shown) {
            this.shown = false;
            this.$emit('close');
            window.removeEventListener('keydown', this.close);
        }
    }

}

