import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';

export enum Theme {
    Light = 'light',
    Dark = 'dark',
}

@Module
export class SettingsModule extends VuexModule {

    private theme: Theme = Theme.Dark;

    public get currentTheme() {
        return this.theme;
    }

    @Mutation
    private updateTheme(theme: Theme) {
        this.theme = theme;
    }
    
}