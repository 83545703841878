import Axios from 'axios';
import API from '@shared/config/api';

import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { IPost, IUser, VoteType, IRating } from '@shared/types';
import { INotification } from '@shared/types/notification.types';

@Module
export class NotificationsModule extends VuexModule {

    private notifications: { [id: string]: INotification } = {};

    public get unreadNotificationsCount() {
        return Object.values(this.notifications).filter(notification => notification.isRead).length;
    }

    public get notificationsStream() {
        return Object.values(this.notifications).reverse();
    }

    @Mutation
    public resetNotifications() {
        this.notifications = {};
    }

    @Mutation
    private commitLoadNotifications(notifications: INotification[]) {
        this.notifications = {
            ...this.notifications,
            ...notifications.reduce((obj, item) => {
                obj[item.id] = item
                return obj
            }, {})
        };
    }

    @Mutation
    private commitMarkAsRead() {
        this.notifications = Object
                                    .values(this.notifications)
                                    .map(notification => ({ ...notification, isRead: true }))
                                    .reduce((obj, item) => {
                                        obj[item.id] = item;
                                        return obj;
                                    }, {})
    }

    @Action({ commit: 'commitLoadNotifications' })
    public async loadNotifications() {
        try {
            return (await Axios.get(API.NotificationsURL)).data.notifications;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    @Action({ commit: 'commitMarkAsRead' })
    public async markNotificationsAsRead() {
        try {
            return await Axios.post(API.NotificationsURL);
        } catch (error) {
            return Promise.reject(error);
        }
    }
    
}