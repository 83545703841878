import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { AuthenticationModule, StreamModule, NotificationsModule, AdministratorModule, SettingsModule, TwitchModule, QuotesModule, PollModule } from './modules';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: 'Frosthub2',
    modules: ['AuthenticationModule', 'SettingsModule']
});

export default new Vuex.Store<any>({

    plugins: [
        vuexLocal.plugin     
    ],

    modules: {
        AuthenticationModule,
        StreamModule,
        NotificationsModule,
        AdministratorModule,
        SettingsModule,
        TwitchModule,
        QuotesModule,
        PollModule
    },

    state: {
        cached: [],
        streamSettings: {
            layout: 'list'
        }
    },

    mutations: {
        addToCache(state, url) {
            if (state.cached.indexOf(url) < 0) {
                state.cached = [...state.cached, url]
            }
        },

        updateStreamLayout(state, layout) {
            state.streamSettings.layout = layout;
        }
    }

});