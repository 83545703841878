import { RouteConfig } from 'vue-router';
import { LazyLoadRoute } from '@shared/router/util';
import { store } from '@shared/store';

export const AdministratorRoutes: RouteConfig[] = [
    {
        name: 'Administrator',
        path: '/administrator-dashboard/:page?/:username?',
        component: () => LazyLoadRoute(import('./AdministratorDashboard.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'] && store.getters['isAdministrator'])
                return next();

            return next('/');
        }
    },
];
