
















import { Vue, Component, Watch } from 'vue-property-decorator';
import { Navigation, MobileNavigation } from './components';
import { Getter, Mutation, Action } from 'vuex-class';

@Component({
    components: { Navigation, MobileNavigation }
})
export default class App extends Vue {

    private transitionName: string = '';

    @Action
    private checkNewAchievements: Function;

    mounted() {
        //(this as any).$snotify.success('Example body content', 'Example Title');
        setInterval(async () => {
            var achs = await this.checkNewAchievements();
            if(achs.success && achs.achievements && achs.achievements.length > 0) {
                for(var ach of achs.achievements) {
                    var achObj = (ach as any);
                    console.log(achObj);

                    (this as any).$snotify.info(achObj.achievement.description, `${achObj.achievement.title}`, {
                        timeout: 5000,
                        icon: "https://cdn4.iconfinder.com/data/icons/social-messaging-ui-color-and-shapes-5/177800/203-512.png"
                    });
                }
            }
        }, 10000);
    }

    @Watch('$route')
    public onRouteChange(to, from) {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        this.transitionName = toDepth < fromDepth || to.path === '/' ? 'slide-right' : 'slide-left'
    }
    
}

