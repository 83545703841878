













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Item extends Vue {
    
    @Prop()
    public to: string;

    private get tag(): string {
        return this.to ? 'router-link' : 'div';
    }

    private onClick(e) {
        this.$emit('click', e);
    }

}

