














































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Logo, DropdownMenu, IconButton, Avatar, Item, Icon } from '@components/UI';
import { Getter, Mutation, Action } from 'vuex-class';
import { NavigationLink, Notifications, ThemeSwitcher } from './index';

@Component({
    components: { Logo, DropdownMenu, IconButton, Avatar, Item, Icon, NavigationLink, Notifications, ThemeSwitcher }
})
export default class Navigation extends Vue {
    
    @Getter
    public isAuthenticated: boolean;

    @Getter
    public currentUser: any;

    @Action
    public logout: Function;

    public async signOut() {
        await this.logout();
        window.localStorage.clear();
        this.$router.push('/authentication/signin');
    }

}

