import Axios from 'axios';
import API from '@shared/config/api';

import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { IPost, IUser } from '@shared/types';

@Module
export class AdministratorModule extends VuexModule {

    private pendingImages: { [name: string]: IPost } = {};
    private pendingUsers: { [name: string]: IUser } = {};
    private userList: { [name: string]: IUser } = {};

    public get currentlyPendingImages() {
        return Object.values(this.pendingImages).reverse();
    }

    public get currentlyPendingUsers() {
        return Object.values(this.pendingUsers).reverse();
    }

    public get currentUserList() {
        return Object.values(this.userList);//.reverse();
    }

    @Mutation
    public resetAdministrator() {
        this.pendingImages = {};
        this.pendingUsers = {};
        this.userList = {};
    }

    @Mutation
    private commitLoadPendingImages(images: any[]) {
        this.pendingImages = {
            ...this.pendingImages,
            ...images.reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {})
        }
    }

    @Mutation
    private commitLoadPendingUsers(users: any[]) {
        console.log(users);
        this.pendingUsers = {
            ...this.pendingUsers,
            ...users.reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {})
        }
    }

    @Mutation
    private commitLoadUserList(users: any[]) {
        console.log(users);
        this.userList = {
            ...this.userList,
            ...users.reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {})
        }
    }

    @Mutation
    private commitAdminApproveUser({ id }) {
        this.pendingUsers = 
            Object
                .values(this.pendingUsers)
                .filter(user => user.id !== id)
                .reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
    }

    @Mutation
    private commitAdminDeleteUser({ id }) {
        this.pendingUsers = 
            Object
                .values(this.pendingUsers)
                .filter(user => user.id !== id)
                .reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});

        this.userList = 
        Object
            .values(this.userList)
            .filter(user => user.id !== id)
            .reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {});
    }

    @Mutation
    private commitAdminApproveImage({ id }) {
        this.pendingImages = 
            Object
                .values(this.pendingImages)
                .filter(image => image.id !== id)
                .reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
    }

    @Mutation
    private commitAdminDeleteImage({ id }) {
        this.pendingImages = 
            Object
                .values(this.pendingImages)
                .filter(image => image.id !== id)
                .reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
    }

    @Action({ commit: 'commitLoadPendingImages' })
    public async adminLoadPendingImages() {
        try {
            const response = await Axios.get(`${API.AdminPendingImagesURL}`);

            if (!response.data.success)
                return Promise.reject(response.data);

            return response.data.images;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({ commit: 'commitLoadPendingUsers' })
    public async adminLoadPendingUsers() {
        try {
            const response = await Axios.get(`${API.AdminPendingUsersURL}`);

            if (!response.data.success)
                return Promise.reject(response.data);

            return response.data.users;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({ commit: 'commitLoadUserList' })
    public async adminLoadUserList() {
        try {
            const response = await Axios.post(`${API.AdminUserListURL}`);

            if (!response.data.success)
                return Promise.reject(response.data);

            return response.data.users;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({ commit: 'commitAdminApproveImage' })
    public async adminApproveImage({ id }) {
        try {
            const response = await Axios.post(`${API.AdminApproveImageURL}/${id}`);

            if (!response.data.success)
                return Promise.reject(response.data);

                return { id, data: response.data };
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({ rawError: true })
    public async adminDeleteImage({ id, pending }) {
        try {
            const response = await Axios.post(`${API.AdminDeleteImageURL}/${id}`);

            if (!response.data.success)
                return Promise.reject(response.data);

            if (pending)
                this.context.commit('commitAdminDeleteImage', { id, data: response.data });
            else
                this.context.commit('removePost', { id, data: response.data });

            return { id, data: response.data };
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action
    public async adminFeatureImage({ id }) {
        try {
            const response = await Axios.post(`${API.AdminFeatureImageURL}/${id}`);

            if (!response.data.success)
                return Promise.reject(response.data);

                return { id, data: response.data };
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action
    public async adminUnFeatureImage({ id }) {
        try {
            const response = await Axios.post(`${API.AdminUnFeatureImageURL}/${id}`);

            if (!response.data.success)
                return Promise.reject(response.data);

            return { id, data: response.data };
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({ commit: 'commitAdminApproveUser' })
    public async adminApproveUser({ id }) {
        try {
            const response = await Axios.post(`${API.AdminApproveUserURL}/${id}`);

            if (!response.data.success)
                return Promise.reject(response.data);

            return {
                id,
                data: response.data
            };
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({ commit: 'commitAdminDeleteUser' })
    public async adminDeleteUser({ id }) {
        try {
            const response = await Axios.post(`${API.AdminDeleteUserURL}/${id}`)

            if (!response.data.success)
                return Promise.reject(response.data);

            return {
                id,
                data: response.data
            };
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({rawError: true})
    public async adminGetUserFromName({name}){
        try {
            const response = await Axios.post(`${API.AdminUserByNameURL}`, {name})
            if (!response.data.success)
                return Promise.reject(response.data);

            return {
                user: (response.data.user as IUser)
            };

        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({rawError: true})
    public async adminSetUserAdmin({id}) {
        try {
            const response = await Axios.post(`${API.AdminAddAdminURL}/${id}`)
            if (!response.data.success)
                return Promise.reject(response.data);

            return true;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }
    @Action({rawError: true})
    public async adminRemoveUserAdmin({id}) {
        try {
            const response = await Axios.post(`${API.AdminRemoveAdminURL}/${id}`)
            if (!response.data.success)
                return Promise.reject(response.data);

            return true;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    

    @Action({rawError: true})
    public async adminSetUserVip({id}) {
        try {
            const response = await Axios.post(`${API.AdminAddVIPURL}/${id}`)
            if (!response.data.success)
                return Promise.reject(response.data);

            return true;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }
    @Action({rawError: true})
    public async adminRemoveUserVip({id}) {
        try {
            const response = await Axios.post(`${API.AdminRemoveVIPURL}/${id}`)
            if (!response.data.success)
                return Promise.reject(response.data);

            return true;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

    @Action({rawError: true})
    public async adminBanUser({uid, reason}) {
        try {
            const response = await Axios.post(`${API.AdminBanUserURL}`, {uid, reason})
            if (!response.data.success)
                return Promise.reject(response.data);

            return true;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }
    @Action({rawError: true})
    public async adminUnbanUser({id}) {
        try {
            const response = await Axios.post(`${API.AdminUnbanUserURL}/${id}`)
            if (!response.data.success)
                return Promise.reject(response.data);

            return true;
        } catch (error) {
            if (error.response.data)
                return Promise.reject(error.response.data);
            
            return Promise.reject();
        }
    }

}