








import { Vue, Component, Prop } from 'vue-property-decorator';
import { LazyImage } from '@components/UI';

@Component({
    components: { LazyImage }
})
export default class Avatar extends Vue {

    @Prop()
    public avatar: string;

    @Prop()
    public twitch: boolean;

    private onClick(e): void {
        this.$emit('click', e);
    }
    
}

