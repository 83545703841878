














import { Vue, Component, Prop } from 'vue-property-decorator';
import { Icon } from '@components/UI';

@Component({
    components: { Icon }
})
export default class NavigationLink extends Vue {
    
    @Prop({ required: true })
    public to: string;

    @Prop({ required: true })
    public icon: string;

    @Prop()
    public extern: boolean;

    @Prop()
    public target: string;
}

