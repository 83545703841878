import Axios from 'axios';
import API from '@shared/config/api';

import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';

@Module
export class QuotesModule extends VuexModule {
    
    private quotes = {};
    private page = 1;
    private pageCount: number | null = null;

    public get quotesStream() {
        return Object.values(this.quotes).reverse();
    }

    @Mutation
    private commitStreamQuotes({ quotes, next, pageCount }) {
        this.quotes = {
            ...this.quotes,
            ...quotes.reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {}),
        };

        this.pageCount = pageCount;
        this.page = next;
    }

    @Action({ rawError: true })
    public async loadQuotes(): Promise<any> {
        if (this.pageCount && this.page > this.pageCount)
            return;

        try {
            const response = await Axios.get(API.QuotesURL, { params: { page: this.page } });

            this.context.commit('commitStreamQuotes', {
                quotes: response.data.quotes,
                next: response.data.page + 1,
                pageCount: response.data.pageCount
            });

            return {
                quotes: response.data.quotes,
                next: response.data.page + 1,
                pageCount: response.data.pageCount
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

}