







import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';

@Component
export default class LazyImage extends Vue {
    
    @Prop()
    public src: string;

    @State('cached')
    public cached: Array<string>;

    @Mutation('addToCache')
    public cache: Function;

    private loading = true;
    private isCached = false;

    @Watch('src', { immediate: true })
    private onSrcChange() {
        this.isCached = this.cached.indexOf(this.src) > -1;
        this.loading = true;

        let img = new Image();
        img.onload = () => { 
            this.loading = false;
            this.cache(this.src);
            this.$emit('load', img);
        };

        img.src = this.src;
    }

}

