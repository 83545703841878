import Axios from 'axios';
import API from '@shared/config/api';

import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { IPoll, IPollOption, IPollVote } from '@shared/types';
import { INotification } from '@shared/types/notification.types';
import { PollOptionModel } from '@app/addpoll/models';

@Module
export class PollModule extends VuexModule {
    private polls: { [name: string]: IPoll } = {};

    private page = 1;
    private pageCount: number | null = null;

    public get pollStream() {
        return {
            polls: Object.values(this.polls).reverse()
        }
    }

    @Mutation
    public resetPollStream() {
        this.page = 1;
        this.polls = {};
    }

    @Mutation
    private commitPollStreamLoad({ polls, next, pageCount }: { polls: any[], next: number, pageCount: number }) {
        this.polls = {
            ...this.polls,
            ...polls.reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {}),
        };

        this.pageCount = pageCount;
        this.page = next;
    }

    @Mutation
    private removePoll({ id }) {
        this.polls = Object
            .values(this.polls)
            .filter(post => post.id !== id)
            .reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {});
    }

    @Action({ rawError: true })
    public async loadPollStream(): Promise<any> {
        if (this.pageCount && this.page > this.pageCount)
            return;

        try {
            const response = await Axios.get(API.PollsURL, { params: { page: this.page } });

            this.context.commit('commitPollStreamLoad', {
                polls: response.data.polls,
                next: response.data.page + 1,
                pageCount: response.data.pageCount
            });

            return {
                polls: response.data.polls,
                next: response.data.page + 1,
                pageCount: response.data.pageCount
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }
 
    @Action({rawError: true})
    public async createPoll({ title, ttl, multipleOptions, usersAddOptions, publicPoll, postStream, options }): Promise<any> {
        var opts = options as Array<PollOptionModel>;

        try {
            /*(const response = await Axios.post(API.CreatePollURL, { 
                title: title,

                allowMultiple: multipleOptions,
                allowUserOptions: usersAddOptions,
                isPublic: publicPoll,
                inStream: postStream,
                
                options: options,
                timeToLive: ttl
            });

            //if (response.data.success)
            //    return { postId, rating, user: this.context.getters.currentUser }
            return response.data;

            return Promise.reject(); */
            throw 'Poll creation disabled';
        } catch (error) {
            return Promise.reject(error);
        }
    }
}