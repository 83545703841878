


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Checkbox extends Vue {
    @Prop({type: Boolean, default: false})
    public radio: boolean;

    @Prop({type: String, default: ''})
    public name: string;

    @Prop({ type: Boolean, default: '' })
    public value: boolean;

    @Prop({ type: String, default: '' })
    public label: string;

    @Prop({ type: String, default: '' })
    public icon: string;

    @Prop({ type: String, default: '' })
    public error: string;

    @Prop({ type: Boolean, default: false })
    public required: boolean;

    @Prop({ type: Boolean, default: false })
    public disabled: boolean;

    @Prop({ type: Boolean, default: false })
    public isTextarea: boolean;

    @Prop()
    public isValid: boolean;

    public focused = false;

    @Prop({ type: String, default: '' })
    public hint: string;

    private onInput(e) {
        //this.value = !this.value;
        this.$emit('input', !this.value);
    }

    private onFocus() { this.focused = true; }

    private onBlur() { this.focused = false; }

    private hasValue() {
        return true;
    }
    
    private getType() {
        if(this.radio)
            return "radio";
        return "checkbox";
    }
}

