import { RouteConfig } from 'vue-router';
import { LazyLoadRoute } from '@shared/router/util';
import { store } from '@shared/store';


export const AuthenticationRoutes: RouteConfig[] = [
    {
        name: 'SignIn',
        path: '/authentication/signin',
        component: () => LazyLoadRoute(import('./SignIn.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'])
                return next('/');

            return next();
        }
    },
    {
        name: 'SignUp',
        path: '/authentication/signup',
        component: () => LazyLoadRoute(import('./SignUp.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'])
                return next('/');

            return next();
        }
    },
    {
        name: 'ForgotPass',
        path: '/authentication/forgotpass',
        component: () => LazyLoadRoute(import('./ForgotPass.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'])
                return next('/');

            return next();
        }
    }
    
];
