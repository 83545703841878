import { RouteConfig } from 'vue-router';
import { LazyLoadRoute } from '@shared/router/util';
import { store } from '@shared/store';

export const QuotesRoutes: RouteConfig[] = [
    {
        name: 'Quotes',
        path: '/quotes',
        component: () => LazyLoadRoute(import('./QuotesView.vue')),
        beforeEnter(to, from, next) {
            if (store.getters['isAuthenticated'])
                return next();

            return next('/authentication/signin');
        }
    },
];
