







import { Vue, Component, Prop } from 'vue-property-decorator';
import { Icons } from '@assets';

@Component
export default class Icon extends Vue {
    
    @Prop({ required: true })
    public icon;

    public get iconDefinition(): string {
        return Icons[this.icon];
    }

}

