import Vue from 'vue';
import Router from 'vue-router';

import { StreamRoutes } from '@app/stream';
import { AboutRoutes } from '@app/about';
import { AuthenticationRoutes } from '@app/authentication';
import { UploadRoutes } from '@app/upload';
import { AdministratorRoutes } from '@app/administrator';
import { SettingsRoutes } from '@app/settings';
import { ClipStreamRoutes } from '@app/clips';
import { QuotesRoutes } from '@app/quotes';
import { AddPollRoutes } from '@app/addpoll';

import { PollRoutes } from '@app/polls';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        ...StreamRoutes,
        ...AboutRoutes,
        ...AuthenticationRoutes,
        ...UploadRoutes,
        ...AdministratorRoutes,
        ...SettingsRoutes,
        ...ClipStreamRoutes,
        ...QuotesRoutes,
        ...AddPollRoutes,
        ...PollRoutes
    ],
    scrollBehavior (to, from, savedPosition) {
        return savedPosition ? savedPosition : { x: 0, y: 0 };
    }
});