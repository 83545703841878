import Axios from 'axios';
import API from '@shared/config/api';

import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { IPost, IUser, VoteType, IRating } from '@shared/types';

@Module
export class StreamModule extends VuexModule {
    
    private posts: { [name: string]: IPost } = {};
    private featured: any = null;
    private sort = 'newest';

    private page = 1;
    private pageCount: number | null = null;

    public get stream() {
        return {
            images: Object.values(this.posts).reverse(),
            featured: this.featured
        }
    }

    public get rating() {
        return (id => {
            const post = this.posts[id];
            
            if (post.ratings)
                return {
                    upvotes: post.ratings.filter(post => post.ratingType === VoteType.UPVOTE).length,
                    downvotes: post.ratings.filter(post => post.ratingType === VoteType.DOWNVOTE).length
                }
            
            return { upvotes: 0, downvotes: 0 };
        });
    }

    public get hasUpvoted() {
        return (id => {
            const user: IUser = this.context.getters.currentUser;
            const ratings = this.posts[id].ratings;

            if (ratings)
                return ratings.find(rating => rating.userId === user.id && rating.ratingType === VoteType.UPVOTE) ? true : false;

            return false;
        });
    }

    public get hasDownvoted() {
        return (id => {
            const user: IUser = this.context.getters.currentUser;
            const ratings = this.posts[id].ratings;

            if (ratings)
                return ratings.find(rating => rating.userId === user.id && rating.ratingType === VoteType.DOWNVOTE) ? true : false;

            return false;
        });
    }

    @Mutation
    public resetStream() {
        this.page = 1;
        this.posts = {};
        this.featured = null;
        this.sort = 'newest';
    }

    @Mutation
    private commitStreamLoad({ featured, images, next, pageCount }: { featured: any, images: any[], next: number, pageCount: number }) {
        this.featured = featured;

        this.posts = {
            ...this.posts,
            ...images.reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {}),
        };

        this.pageCount = pageCount;
        this.page = next;
    }

    @Mutation
    private commitPostRating({ postId, rating, user }: { postId: number, rating: VoteType, user: IUser }) {
        if (rating === VoteType.REMOVE) {
            this.posts = {
                ...this.posts,
                [postId]: {
                    ...this.posts[postId],
                    ratings: this.posts[postId].ratings.filter(rating => rating.userId !== user.id)
                }
            }

            return;
        }

        this.posts = {
            ...this.posts,
            [postId]: {
                ...this.posts[postId],
                ratings: [
                    ...this.posts[postId].ratings.filter(rating => rating.userId !== user.id),
                    { ratingId: 0, ratingType: rating, userId: user.id }
                ]
            }
        }
    }

    @Mutation
    private removePost({ id }) {
        this.posts = Object
            .values(this.posts)
            .filter(post => post.id !== id)
            .reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {});
    }

    @Action({ rawError: true })
    public async loadStream(): Promise<any> {
        if (this.pageCount && this.page > this.pageCount)
            return;

        try {
            const response = await Axios.get(API.StreamURL, { params: { page: this.page, sort: this.sort } });

            this.context.commit('commitStreamLoad', {
                featured: response.data.featured,
                images: response.data.images,
                next: response.data.page + 1,
                pageCount: response.data.pageCount
            });

            return {
                featured: response.data.featured,
                images: response.data.images,
                next: response.data.page + 1,
                pageCount: response.data.pageCount
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    @Action({ commit: 'commitPostRating' })
    public async ratePost({ postId, rating }): Promise<any> {
        try {
            const response = await Axios.post(API.RateURL, { image: postId, rating });

            if (response.data.success)
                return { postId, rating, user: this.context.getters.currentUser }

            return Promise.reject();
        } catch (error) {
            return Promise.reject(error);
        }
    }

    @Action
    public async uploadImage({ title, content, file, progressCallback }: { title: string, content: any, file: File, progressCallback: any }) {
        try {
            const form = new FormData();

            form.append('title', title);
            form.append('image', file);
            form.append('content', content);

            var conf = {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: progressCallback || null
            }

            const response = await Axios.post(API.UploadURL, form, conf);
            
            //console.log(response.data);
            if (!response.data.success)
                return response.data;

            return response.data;
            //throw 'Uploads Disabled'
        } catch (error) {
            if (error.response.data)
                    return Promise.reject(error.response.data);
                
            return Promise.reject();
        }
    }

    @Action
    public async sharePost({ id }) {
        try {
            //const response = await Axios.post(`${API.ShareURL}/${id}`);

            //return Promise.resolve(response.data);
            throw 'Sharing Disabled'
        } catch (error) {
            return Promise.reject(error);
        }
    }
    
}